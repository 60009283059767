<template>
	
	<div class="cont pw" id="p09" style="width: 100%; height: 100%; position: fixed; left: 0; top: 0; background: none; z-index: 9999;">
		<div class="popup-list" style="width: 100%; height: 100%; position: fixed; left: 0; top: 0;">
			<div class="popup type02" style="width: 550px; margin: 10% auto; transform: none; overflow: hidden; position: relative !important; z-index: 9999;">
				<div class="p-header">
					<div class="title">{{ program.name }}</div>
				</div>
				<div class="p-body">
					<div class="c01">
						<div class="pc-body">
							<div class="item_list">
								<div class="item">
									<div class="title"  style="font-size: 1.2em !important;">기존 비밀번호</div>
									<div class="desc"  style="font-size: 1.2em !important;"><input type="password" 
									v-model="item.password"
									/></div>
								</div>
								<div class="item">
									<div class="title"  style="font-size: 1.2em !important;">신규 비밀번호</div>
									<div class="desc"  style="font-size: 1.2em !important;"><input type="password" 
									v-model="item.newPassword"
									:rules="[rules.password(item, 'newPassword')]"
									/></div>
								</div>
								<div class="item">
									<div class="title"   style="font-size: 1.2em !important;">비밀번호 확인</div>
									<div class="desc"  style="font-size: 1.2em !important;"><input type="password" 
									v-model="item.passwordConfirm"/></div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="p-button">
					<v-row>
						<v-col>
							<v-btn
								class="success"
								style="background: #00bfa5;"
								block tile
								@click="save"
							>확인</v-btn>
						</v-col>
						<v-col>
							<v-btn
								class="success"
								style="background: #bbb;"
								block tile
								@click="close"
							>취소</v-btn>
						</v-col>
					</v-row>
				</div>
			</div>
		</div>
	</div>

</template>

<script>

	import { Axios } from '@/resources/axios/axios'
	import { rules } from '@/resources/rules/rules'
	
	export default{
		name: 'AgentPopupItem'
		,props: []
		,data: function(){
			return {
				program: {
					name: '비밀번호 변경'
				}
				,item: {
				}
				,rules: rules
			}
		}
		,methods: {
			save: async function(){
				try{
					const result = await Axios({
						method: 'post'
						,url: '/users/me/password'
						,data: this.item
						,authorize: true
					})
					
					if(result.success){
						this.$emit('setNotify', { type: 'success', message: '비밀번호가 변경되었습니다'})
						this.$emit('setOverlay', false)
					}else{
						this.$emit('setNotify', { type: 'error', message: result.message})
					}
				}catch(E){
					this.$emit('setNotify', { type: 'error', message: E.status})
					this.$emit('setOverlay', false)
				}finally{
					//
				}	
			}
			,close: function(){
					this.$emit('setOverlay', false)
			}
		}
	}
</script>

<style>
	.pw input {font-size: 1em !important; border: 1px solid #ddd; padding: 5px; color: black;}
</style>